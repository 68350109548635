import { create } from 'zustand'

interface BexGroupStore {
  bexGroup: Record<string, string>
  setBexGroup: (
    updater: Record<string, string> | ((prev: Record<string, string>) => Record<string, string>)
  ) => void
}

const useBexGroupStore = create<BexGroupStore>((set) => ({
  bexGroup: {},
  setBexGroup: (updater) =>
    set((state) => ({
      bexGroup: typeof updater === 'function' ? updater(state.bexGroup) : updater,
    })),
}))

export default useBexGroupStore
