import classNames from 'classnames';
import React from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { Button } from '../Button';

interface PagenationProps {
  currentPage: number;
  countPerPage: number;
  totalCount: number;
  pagePerGroup?: number;
  onChange: (page: number) => void;
}

const Pagenation: React.FC<PagenationProps> = ({
  currentPage,
  countPerPage,
  totalCount,
  pagePerGroup = 5,
  onChange,
}) => {
  const pageCount = Math.ceil(totalCount / countPerPage);
  const startPage =
    Math.floor((currentPage - 1) / pagePerGroup) * pagePerGroup + 1;
  const endPage = Math.min(startPage + pagePerGroup - 1, pageCount);
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  const isFirstGroup = startPage === 1;
  const isLastGroup = endPage === pageCount;

  const goToPreviousGroup = () => {
    const previousGroupStartPage = Math.max(startPage - pagePerGroup, 1);
    onChange(previousGroupStartPage);
  };

  const goToNextGroup = () => {
    const nextGroupStartPage = Math.min(startPage + pagePerGroup, pageCount);
    onChange(nextGroupStartPage);
  };

  return (
    <ul className="flex gap-1.5 justify-center">
      <li>
        <Button
          variant="ghost"
          className={classNames(
            'w-10 h-10 p-2.5 flex justify-center items-center',
            isFirstGroup && 'text-border-10 bg-transparent'
          )}
          onClick={goToPreviousGroup}
          suffix={() => <MdChevronLeft size={24} />}
          disabled={isFirstGroup}
        ></Button>
      </li>
      {pageNumbers.map((page, index) => {
        return (
          <li key={index}>
            <button
              className={classNames(
                'w-10 h-10 p-2.5 rounded-lg flex justify-center items-center',
                'hover:bg-background-dark',
                'hover:text-gray-90',
                currentPage === page ? '!bg-primary' : 'bg-white',
                { 'border border-border-10': currentPage !== page },
                { '!text-white': currentPage === page }
              )}
              onClick={() => onChange(page)}
            >
              <div>{page}</div>
            </button>
          </li>
        );
      })}
      <Button
        variant="ghost"
        className={classNames(
          'w-10 h-10 p-2.5 flex justify-center items-center',
          isLastGroup && 'text-border-10 bg-transparent'
        )}
        onClick={goToNextGroup}
        suffix={() => <MdChevronRight size={24} />}
        disabled={isLastGroup}
      ></Button>
    </ul>
  );
};

export default Pagenation;
