import { useEffect } from 'react'
import { useBexExperiment } from '@packages/bex'

import useBexGroupStore from 'store/bex'

export const useBexExperimentGroup = (experimentKey: string, enable: boolean = true) => {
  const { bexGroup, setBexGroup } = useBexGroupStore()
  const { isTreatmentGroup, isLoaded, unitInfo } = useBexExperiment(experimentKey, {
    enable: bexGroup[experimentKey] == undefined && enable,
  })

  useEffect(() => {
    if (isLoaded) {
      setBexGroup((prevGroup) => ({
        ...prevGroup,
        [experimentKey]: unitInfo?.treatmentKey || 'A',
      }))
    }
  }, [isTreatmentGroup, isLoaded, experimentKey, setBexGroup, unitInfo])

  return bexGroup[experimentKey]
}

export const useSetBexExperimentGroup = () => {
  const { setBexGroup } = useBexGroupStore()
  const setBexExperimentGroup = (experimentKey: string, treatmentKey: string) => {
    setBexGroup((prevGroup) => ({
      ...prevGroup,
      [experimentKey]: treatmentKey,
    }))
  }

  return setBexExperimentGroup
}
