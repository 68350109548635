import React, { useEffect } from 'react';

interface CustomDialogProps {
  className?: string;
  children?: React.ReactNode;
  onBgClick?: () => void;
}

export function CustomDialog({
  className,
  children,
  onBgClick,
}: CustomDialogProps) {
  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black/80"
        onClick={(e) => {
          e.stopPropagation();
          if (e.target === e.currentTarget) {
            onBgClick?.();
          }
        }}
      />
      <div className={`fixed inset-0 ${className}`}>{children}</div>
    </div>
  );
}
