import { useBexUser } from '@packages/bex'
import { useCallback } from 'react'
import { Types } from '@amplitude/analytics-browser'

import { MixpanelEventLogger } from '../lib/analyze/MixpanelEventLogger'

export const useEventLogging = () => {
  const mixpanelEventLogger = MixpanelEventLogger.getInstance()
  const { setBexUserId } = useBexUser()

  const getPlatform = () => {
    return window.flutter_inappwebview || window.AndroidBridge ? 'app' : 'web'
  }

  const setUserId = useCallback(
    ({ userId }: { userId: string | undefined }) => {
      if (userId) {
        setBexUserId({ userId })
      }
      mixpanelEventLogger.setUserId(userId)
    },
    [mixpanelEventLogger]
  )

  const getUserIdWithoutLogIn = useCallback(() => {
    return {
      mixpanel: mixpanelEventLogger.getUserId(),
      amplitude: '',
    }
  }, [mixpanelEventLogger])

  const reset = useCallback(() => {
    mixpanelEventLogger.reset()
  }, [mixpanelEventLogger])

  const getUserId = useCallback(() => {
    const userId = mixpanelEventLogger.getUserId()
    return userId
  }, [mixpanelEventLogger])

  const trackExperiment = useCallback(
    ({
      experimentKey,
      treatmentKey,
    }: {
      experimentKey: string
      treatmentKey: string
    }) => {
      track({
        name: '$experiment_started',
        properties: {
          'Experiment name': experimentKey,
          'Variant name': treatmentKey,
          'Variation ID': treatmentKey,
        },
      })
    },
    [mixpanelEventLogger]
  )

  const setUserProperties = useCallback(
    ({ properties }: { properties: object | null }) => {
      mixpanelEventLogger.setUserProperties(properties)
    },
    [mixpanelEventLogger]
  )

  const track = useCallback(
    ({
      name,
      properties,
      options,
      callback,
    }: {
      name: string
      properties?: object
      options?: Types.EventOptions
      callback?: any
    }) => {
      mixpanelEventLogger.track(
        name,
        {
          platform: getPlatform(),
          ...properties,
        },
        options,
        callback
      )
    },
    [mixpanelEventLogger]
  )

  const trackButtonClick = useCallback(
    ({
      name,
      properties,
      pagePath,
      options,
      callback,
    }: {
      name: string
      properties?: object
      pagePath?: string
      options?: Types.EventOptions
      callback?: any
    }) => {
      track({
        name: `[Button Click] ${name}`,
        properties: Object.assign(
          { pagePath: pagePath || window.location.pathname },
          properties
        ),
        options,
        callback,
      })
    },
    [mixpanelEventLogger]
  )

  const trackAction = useCallback(
    ({
      name,
      properties,
      pagePath,
      options,
    }: {
      name: string
      properties?: object
      pagePath?: string
      options?: Types.EventOptions
    }) => {
      track({
        name: `[Action] ${name}`,
        properties: Object.assign(
          { pagePath: pagePath || window.location.pathname },
          properties
        ),
        options,
      })
    },
    [mixpanelEventLogger]
  )

  const trackPageView = useCallback(
    ({
      pagePath,
      pageName,
      properties,
      options,
    }: {
      pagePath?: string
      pageName: string
      properties?: object
      options?: Types.EventOptions
    }) => {
      track({
        name: `[Page View] ${pageName ? pageName : 'Untaged'}`,
        properties: Object.assign(
          { pagePath: pagePath || window.location.pathname },
          properties
        ),
        options,
      })
    },
    [mixpanelEventLogger]
  )

  const trackShow = useCallback(
    ({
      name,
      pagePath,
      properties,
      options,
    }: {
      name: string
      pagePath?: string
      properties?: object
      options?: Types.EventOptions
    }) => {
      track({
        name: `[Show] ${name ? name : 'Untaged'}`,
        properties: Object.assign(
          { pagePath: pagePath || window.location.pathname },
          properties
        ),
        options,
      })
    },
    [mixpanelEventLogger]
  )

  return {
    getUserId,
    getUserIdWithoutLogIn,
    setUserId,
    setUserProperties,
    reset,
    track,
    trackButtonClick,
    trackAction,
    trackPageView,
    trackShow,
    trackExperiment,
  }
}
